import React from 'react'
import { Switch, Upload, Button, Tooltip, Spin, Popover } from 'antd'
import { CloseOutlined, MoreOutlined } from '@ant-design/icons'
import { getImage } from '../../../../config/utils'
import PartnerListConfig from './PartnersListConfig'

export const getPartnersCategoryColumns = ({
  translateTextI18N,
  handleCategoryStatusChange,
  handleCustomImageUpload,
  showDeleteConfirmation,
  fetchPartnersByCategory,
  partnersData,
  handlePartnerStatusChange,
  handlePartnerImageUpload,
  showPartnerImageDeleteConfirmation,
  loadingStates,
  customImages,
  hotelInfo,
  message,
  openPopoverId,
  setOpenPopoverId
}) => [
  {
    title: translateTextI18N('Partners Category Name'),
    dataIndex: 'title',
    key: 'title',
    width: 180,
    render: (title, record) => (
      <div className='category-name'>{translateTextI18N(title)}</div>
    ),
  },
  {
    title: translateTextI18N('Action'),
    dataIndex: 'effectiveStatus',
    key: 'status',
    width: 100,
    render: (effectiveStatus, record) => (
      <div className='customSwitch'>
        <Switch
          checked={effectiveStatus === 'active'}
          onChange={() =>
            handleCategoryStatusChange(
              record.id,
              effectiveStatus === 'active' ? 'inactive' : 'active'
            )
          }
        />
      </div>
    ),
  },
  {
    title: translateTextI18N('Custom Image'),
    dataIndex: 'customImage',
    key: 'customImage',
    width: 120,
    render: (_, record) => {
      const isLoading = loadingStates[record.id]
      const imageUrl =
        customImages[record.id] || record.hotelConfiguration?.customImage

      return (
        <div className='custom-image-container'>
          {imageUrl ? (
            <div className='image-preview'>
              <img src={imageUrl} alt={record.title} className='custom-image' />
              <CloseOutlined
                className='delete-icon'
                onClick={() => showDeleteConfirmation(record.id)}
              />
            </div>
          ) : (
            <Tooltip
              title={translateTextI18N(
                'Image size should be less than or equal to 2 MB'
              )}
            >
              <Upload
                accept='image/webp'
                beforeUpload={async file => {
                  if (file.size > 2 * 1024 * 1024) {
                    message.error(
                      translateTextI18N('Image size should not exceed 2MB')
                    )
                    return false
                  }
                  await handleCustomImageUpload(file, record.id)
                  return false
                }}
                showUploadList={false}
                disabled={!hotelInfo.enableDepartmentCustomImage}
              >
                <div className='uploadimgtypedeptimg'>
                  {isLoading ? (
                    <Spin />
                  ) : (
                    <Button disabled={!hotelInfo?.enableDepartmentCustomImage}>
                      <img
                        src={getImage('images/uploadfig.svg')}
                        alt={translateTextI18N('Upload')}
                      />
                    </Button>
                  )}
                </div>
              </Upload>
            </Tooltip>
          )}
        </div>
      )
    },
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
    width: 50,
    render: (_, record) => (
      
      <Popover
        content={
          <PartnerListConfig
            category={record}
            partners={partnersData[record.id] || []} // Pass partners data
            translateTextI18N={translateTextI18N}
            handlePartnerStatusChange={handlePartnerStatusChange}
            handlePartnerImageUpload={handlePartnerImageUpload}
            showPartnerImageDeleteConfirmation={
              showPartnerImageDeleteConfirmation
            }
            loadingStates={loadingStates}
            hotelInfo={hotelInfo}
            message={message}
          />
        }
        trigger='click'
        placement='bottomLeft'
        overlayClassName='partners-popover'
        overlayStyle={{ maxWidth: '95%' }}
        visible={openPopoverId === record.id}
        onVisibleChange={visible => {
          if (visible) {
            setOpenPopoverId(record.id)
            fetchPartnersByCategory(record.id)
          } else {
            setOpenPopoverId(null)
          }
        }}
      >
        <Button type='text' className='more-button' icon={<MoreOutlined />} />
      </Popover>
    ),
  },
]
