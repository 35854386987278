// PartnerList.js
import React from 'react'
import { Switch, Upload, Button, Tooltip, Spin, Empty } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { getImage } from '../../../../config/utils'

const PartnerListConfig = ({
  category,
  partners, // Add this prop
  translateTextI18N,
  handlePartnerStatusChange,
  handlePartnerImageUpload,
  showPartnerImageDeleteConfirmation,
  loadingStates,
  hotelInfo,
  message,
}) => {
  console.log('partners', partners)
  // Check if partners array is empty
  if (!partners || partners.length === 0) {
    return (
      <>
        <ul className='list-unstyled'></ul>
        <Empty
          description={translateTextI18N(
            'No partners available for this region.'
          )}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
        <ul />
      </>
    )
  }
  return (
    <ul className='list-unstyled'>
      {partners.map((partner, idx) => (
        <li key={idx} className='partner-item'>
          {translateTextI18N(partner.title)}
          <div className='d-flex align-items-center ml-auto'>
            {/* Partner Image Section */}
            {/* <div className='custom-image-container mr-3'>
              {partner.imageUrl ? (
                <div className='image-preview'>
                  <img
                    src={partner.imageUrl}
                    alt={partner.name}
                    className='custom-image'
                  />
                  <CloseOutlined
                    className='delete-icon'
                    onClick={() =>
                      showPartnerImageDeleteConfirmation(partner.id)
                    }
                  />
                </div>
              ) : (
                <Tooltip
                  title={translateTextI18N(
                    'Image size should be less than or equal to 2 MB'
                  )}
                >
                  <Upload
                    accept='image/webp'
                    beforeUpload={async file => {
                      if (file.size > 2 * 1024 * 1024) {
                        message.error(
                          translateTextI18N('Image size should not exceed 2MB')
                        )
                        return false
                      }
                      await handlePartnerImageUpload(
                        file,
                        category.id,
                        partner.id
                      ) // Pass categoryId
                      return false
                    }}
                    showUploadList={false}
                    disabled={!hotelInfo.enablePartnerCustomImage}
                  >
                    <div className='uploadimgtypedeptimg'>
                      {loadingStates[partner.id] ? (
                        <Spin />
                      ) : (
                        <Button disabled={!hotelInfo?.enablePartnerCustomImage}>
                          <img
                            src={getImage('images/uploadfig.svg')}
                            alt={translateTextI18N('Upload')}
                          />
                        </Button>
                      )}
                    </div>
                  </Upload>
                </Tooltip>
              )}
            </div> */}

            {/* Partner Status Switch */}
            <div className='customSwitch'>
              <Switch
                checked={partner.effectiveStatus === 'active'}
                onChange={() => {
                  handlePartnerStatusChange(
                    category.id,
                    partner.id,
                    partner.effectiveStatus === 'active' ? 'inactive' : 'active'
                  )
                }}
              />
            </div>
          </div>
        </li>
      ))}
    </ul>
  )
}

export default PartnerListConfig
