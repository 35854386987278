import React, { useEffect, useState, useContext } from 'react'
import { Table, message, Modal, Button } from 'antd'
import { getPartnersCategoryColumns } from './Configs/PartnersCategoryConfig'
import { APIs } from '../../../config/constants'
import { GetCurrentUserToken } from '../../../services/user'
import AxiosHelper from '../../../utility/axiosHelper'
import { AuthContext } from '../../../Router/AuthRouteProvider'
import ErrorModal from '../../Common/Modals/ErrorModal'
import SuccessModal from '../../Common/Modals/SuccessModal'
import DeleteModal from '../../Common/Modals/DeleteModal'
import { CloseOutlined } from '@ant-design/icons'
import { isFileSizeValid } from '../../../services/hotels'
import { useCustomI18NTranslatorHook } from '../../../utility/globalization'

const PartnersManagement = () => {
  // Existing states
  const [categories, setCategories] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [loadingStates, setLoadingStates] = useState({})
  const [customImages, setCustomImages] = useState({})
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [categoryToDelete, setCategoryToDelete] = useState(null)
  const [errorModalVisible, setErrorModalVisible] = useState(false)
  const [successModalVisible, setSuccessModalVisible] = useState(false)
  const [modalMessage, setModalMessage] = useState('')
  const [deleteLoading, setDeleteLoading] = useState(false)
  const { hotelId, hotelInfo } = useContext(AuthContext)
  const [openPopoverId, setOpenPopoverId] = useState(null)
  const [translateTextI18N] = useCustomI18NTranslatorHook()

  // Partner-specific states

  const [partnerToDelete, setPartnerToDelete] = useState(null)
  const [deletePartnerImageModalVisible, setDeletePartnerImageModalVisible] =
    useState(false)
  const [partnersData, setPartnersData] = useState({})

  const showErrorModal = message => {
    setModalMessage(message)
    setErrorModalVisible(true)
  }

  const showSuccessModal = message => {
    setModalMessage(message)
    setSuccessModalVisible(true)
  }

  const fetchCategories = async () => {
    if (!hotelId) return

    try {
      const token = await GetCurrentUserToken()
      const options = {
        url: `${APIs.FETCH_PARTNER_CATEGORY}?hotelId=${hotelId}`,
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept-Language': 'en',
        },
      }
      const response = await AxiosHelper.call(options)

      if (response.statusCode === 200 && response.message?.data) {
        setCategories(response.message.data)
      } else {
        setCategories([])
        setError('No categories available')
      }
      setLoading(false)
    } catch (error) {
      console.error('Fetch Categories Error:', error)
      setError(error)
      setLoading(false)
    }
  }

  const fetchPartnersByCategory = async categoryId => {
    try {
      const token = await GetCurrentUserToken()
      const options = {
        url: `${APIs.FETCH_PARTNER_LIST_BY_CATEGORY}`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          categoryId: categoryId,
          hotelId: hotelId,
        },
      }
      const response = await AxiosHelper.call(options)

      if (response.statusCode === 200 && response.message?.data) {
        // Filter partners based on hotel's country and region
        const filteredPartners = response.message.data.filter(partner => {
          // If partner is global, include it regardless of geography
          if (partner.isGlobal === true) {
            return true
          }

          // If not global, apply geography filters
          const partnerCountries =
            partner.geography?.countries?.map(country =>
              country.toLowerCase()
            ) || []

          const partnerRegions =
            partner.geography?.regions?.map(region => region.toLowerCase()) ||
            []

          const countryMatch =
            partnerCountries.length > 0
              ? partnerCountries.includes(hotelInfo?.countryName.toLowerCase())
              : false

          const regionMatch =
            partnerRegions.length > 0
              ? partnerRegions.includes(hotelInfo?.stateName.toLowerCase())
              : false

          return countryMatch && regionMatch
        })

        setPartnersData(prev => ({
          ...prev,
          [categoryId]: filteredPartners,
        }))
      }
    } catch (error) {
      console.error('Fetch Partners Error:', error)
      showErrorModal('Failed to fetch partners')
    }
  }

  const handleCategoryStatusChange = async (categoryId, newStatus) => {
    try {
      setLoading(true)
      const token = await GetCurrentUserToken()
      const options = {
        url: `${APIs.UPDATE_PARTNER_CATEGORY_STATUS}/${categoryId}`,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          status: newStatus,
          hotelId: hotelId,
        },
      }

      const response = await AxiosHelper.call(options)
      if (response.statusCode === 200) {
        await fetchCategories()
        message.success('Category status updated successfully')
      } else {
        message.error('Failed to update category status')
      }
    } catch (error) {
      message.error('Failed to update category status')
    } finally {
      setLoading(false)
    }
  }

  // Partner-specific handlers
  const handlePartnerStatusChange = async (
    categoryId,
    partnerId,
    newStatus
  ) => {
    try {
      setLoading(true)
      setOpenPopoverId(null)
      console.log(partnerId)
      const token = await GetCurrentUserToken()
      const options = {
        url: `${APIs.UPDATE_PARTNERS_STATUS}/${partnerId}`,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          status: newStatus,
          hotelId: hotelId,
          categoryId: categoryId,
        },
      }

      const response = await AxiosHelper.call(options)
      if (response.statusCode === 200) {
        await fetchPartnersByCategory(categoryId)
        message.success('Partner status updated successfully')
      } else {
        message.error('Failed to update partner status')
      }
    } catch (error) {
      message.error('Failed to update partner status')
    } finally {
      setLoading(false)
    }
  }

  const handlePartnerImageUpload = async (file, partnerId) => {
    try {
      if (!isFileSizeValid(file)) {
        showErrorModal('Image size should not exceed 2MB')
        return false
      }

      setLoadingStates(prev => ({ ...prev, [partnerId]: true }))

      const formData = new FormData()
      formData.append('image', file)
      formData.append('partnerId', partnerId)
      formData.append('hotelId', hotelId)
      // formData.append('categoryId', selectedCategory.id)

      const token = await GetCurrentUserToken()
      const options = {
        url: APIs.UPLOAD_PARTNER_IMAGE,
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      }

      const response = await AxiosHelper.call(options)
      if (response.statusCode === 200) {
        await fetchCategories()
        showSuccessModal('Partner image uploaded successfully')
        return false
      }

      showErrorModal(response.message || 'Failed to upload image')
      return false
    } catch (error) {
      showErrorModal(error.message || 'Failed to upload image')
      return false
    } finally {
      setLoadingStates(prev => ({ ...prev, [partnerId]: false }))
    }
  }

  // Category handlers
  const handleCustomImageUpload = async (file, categoryId) => {
    try {
      if (!isFileSizeValid(file)) {
        showErrorModal('Image size should not exceed 2MB')
        return false
      }

      setLoadingStates(prev => ({ ...prev, [categoryId]: true }))

      const formData = new FormData()
      formData.append('image', file)
      formData.append('categoryId', categoryId)
      formData.append('hotelId', hotelId)

      const token = await GetCurrentUserToken()
      const options = {
        url: APIs.UPLOAD_PARTNER_CATEGORY_IMAGE,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: formData,
      }
      const response = await AxiosHelper.call(options)
      if (response.statusCode === 200) {
        setCustomImages(prev => ({
          ...prev,
          [categoryId]:
            response.message.data?.imageUrl || response.message.imageUrl,
        }))
        showSuccessModal('Image uploaded successfully')
        await fetchCategories()
        return false
      }

      showErrorModal(response.message || 'Failed to upload image')
      return false
    } catch (error) {
      showErrorModal(error.message || 'Failed to upload image')
      return false
    } finally {
      setLoadingStates(prev => ({ ...prev, [categoryId]: false }))
    }
  }

  const showDeleteConfirmation = categoryId => {
    setCategoryToDelete(categoryId)
    setDeleteModalVisible(true)
  }

  const showPartnerImageDeleteConfirmation = partnerId => {
    setPartnerToDelete(partnerId)
    setDeletePartnerImageModalVisible(true)
  }

  const handleDeleteImage = async () => {
    try {
      setDeleteLoading(true)

      const token = await GetCurrentUserToken()
      const options = {
        url: `${APIs.DELETE_PARTNER_CATEGORY_IMAGE}/${categoryToDelete}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          hotelId: hotelId,
        },
      }

      const response = await AxiosHelper.call(options)
      if (response.statusCode === 200) {
        setCustomImages(prev => {
          const newImages = { ...prev }
          delete newImages[categoryToDelete]
          return newImages
        })
        showSuccessModal('Image deleted successfully')
        await fetchCategories()
      } else {
        throw new Error(response.message || 'Failed to delete image')
      }
    } catch (error) {
      showErrorModal('Failed to delete image: ' + error.message)
    } finally {
      setDeleteLoading(false)
      setDeleteModalVisible(false)
      setCategoryToDelete(null)
    }
  }

  const handleDeletePartnerImage = async () => {
    try {
      setDeleteLoading(true)

      const token = await GetCurrentUserToken()
      const options = {
        url: `${APIs.DELETE_PARTNER_IMAGE}/${partnerToDelete}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          hotelId: hotelId,
          // categoryId: selectedCategory.id,
        },
      }

      const response = await AxiosHelper.call(options)
      if (response.statusCode === 200) {
        await fetchCategories()
        showSuccessModal('Partner image deleted successfully')
      } else {
        throw new Error(response.message || 'Failed to delete image')
      }
    } catch (error) {
      showErrorModal('Failed to delete image: ' + error.message)
    } finally {
      setDeleteLoading(false)
      setDeletePartnerImageModalVisible(false)
      setPartnerToDelete(null)
    }
  }

  useEffect(() => {
    if (hotelId) {
      fetchCategories()
    }
  }, [hotelId])

  const columns = getPartnersCategoryColumns({
    translateTextI18N,
    handleCategoryStatusChange,
    handleCustomImageUpload,
    showDeleteConfirmation,
    fetchPartnersByCategory,
    partnersData,
    handlePartnerStatusChange,
    handlePartnerImageUpload,
    showPartnerImageDeleteConfirmation,
    loadingStates,
    customImages,
    hotelInfo,
    message,
    openPopoverId,
    setOpenPopoverId,
  })

  return (
    <>
      <Table
        columns={columns}
        dataSource={categories}
        pagination={false}
        scroll={{ y: 250 }}
        loading={loading}
        rowKey='id'
      />

      {/* Category Image Delete Modal */}
      <Modal
        visible={deleteModalVisible}
        onOk={handleDeleteImage}
        onCancel={() => setDeleteModalVisible(false)}
        className='deleteModal cmnModal'
        footer={null}
        centered
      >
        <DeleteModal
          title='Confirm Delete'
          message='Do you want to delete this custom image?'
        />
        <div className='modalFooter'>
          <Button
            className='grayBtn'
            onClick={() => setDeleteModalVisible(false)}
          >
            Cancel
          </Button>
          <Button
            className='blueBtn ml-3 ml-lg-4'
            onClick={handleDeleteImage}
            loading={deleteLoading}
          >
            Delete
          </Button>
        </div>
      </Modal>

      {/* Partner Image Delete Modal */}
      <Modal
        visible={deletePartnerImageModalVisible}
        onOk={handleDeletePartnerImage}
        onCancel={() => setDeletePartnerImageModalVisible(false)}
        className='deleteModal cmnModal'
        footer={null}
        centered
      >
        <DeleteModal
          title='Confirm Delete'
          message='Do you want to delete this partner image?'
        />
        <div className='modalFooter'>
          <Button
            className='grayBtn'
            onClick={() => setDeletePartnerImageModalVisible(false)}
          >
            Cancel
          </Button>
          <Button
            className='blueBtn ml-3 ml-lg-4'
            onClick={handleDeletePartnerImage}
            loading={deleteLoading}
          >
            Delete
          </Button>
        </div>
      </Modal>

      {/* Error Modal */}
      <Modal
        visible={errorModalVisible}
        onCancel={() => setErrorModalVisible(false)}
        footer={null}
        closeIcon={<CloseOutlined />}
        centered
        className='customModal errorModal'
      >
        <ErrorModal title={modalMessage} />
      </Modal>

      {/* Success Modal */}
      <Modal
        visible={successModalVisible}
        onCancel={() => setSuccessModalVisible(false)}
        footer={null}
        closeIcon={<CloseOutlined />}
        centered
        className='customModal successModal'
      >
        <SuccessModal title={modalMessage} />
      </Modal>
    </>
  )
}

export default PartnersManagement
